import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";
import HorizontalTestimonial from "../../components/horizontal-testimonial";

const BusinessMentoringPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        heroImg: file(relativePath: { eq: "11.jpg" }) {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        liz: file(relativePath: { eq: "testimonials/liz-george.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
        aryajit: file(
          relativePath: { eq: "testimonials/aryajit-heppell.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
        selda: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
      }
    `
  );
  const siteUrl = data.site.siteMetadata.siteUrl;
  const schemaImage = data.heroImg.publicURL;
  const title = "Business Mentor in London";
  const description =
    "Business mentoring can shift your thinking and focus, and doing some things differently, could increase your businesses profitability and growth.";
  const heroImage = data.heroImg.childImageSharp.fluid;
  const liz = data.liz.childImageSharp.gatsbyImageData;
  const selda = data.selda.childImageSharp.gatsbyImageData;
  const aryajit = data.aryajit.childImageSharp.gatsbyImageData;
  const ogImg = `${siteUrl}${schemaImage}`;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Business Mentoring London",
        item: {
          url: `${siteUrl}/business-mentoring`,
          id: `${siteUrl}/business-mentoring`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={title}
        description={description}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/business-mentoring`,
          title: `${title}`,
          description: `${description}`,
          images: [
            {
              url: `${ogImg}`,
              width: 1920,
              height: 1080,
              alt: "Business Mentoring in London",
            },
          ],
        }}
      />
      <Hero
        isDarken={true}
        backgroundImage={heroImage}
        title="Business Mentor in London"
        description1=""
        description2=""
      />
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">Are you happy in your business? </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                As a self-employed small business owner, what if you, by
                shifting your thinking and focus, and doing some things
                differently, could increase your businesses profitability,
                growth and ultimately, see more cash in the bank?
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <h3 className="py-3">
                What would life feel like to you if this was possible?
              </h3>
              <ul className="pl-4">
                <li>Would you be able to take more time off? </li>
                <li>Could you spend more time with your family?</li>
                <li>Would money worries be a thing of the past?</li>
                <li>Could you create more passive income?</li>
                <li>Where would you be living, or driving...or sailing?</li>
                <li>What school would your children be going to?</li>
                <li>Will you give more time, money or expertise to charity?</li>
              </ul>
              <p>
                Whatever it may be, I bet life would be easier. So, perhaps it’s
                time to focus on you, your wellbeing and your future?
              </p>
              <p>
                To do this, though, you need to step back. To take an honest
                snapshot of the bigger picture behind your business and your
                motivations.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
        <Container>
          <Row>
            <Col>
              <StaticImage
                className="float-lg-right my-3 my-xl-0 ml-xl-3 service-page-body-image"
                src="../../images/17.jpg"
              />
              <h3 className="py-3">Why aren't you where you want to be?</h3>
              <ul className="pl-4">
                <li>Right now, what’s personally holding you back?</li>
                <li>
                  Why aren’t you as profitable as you need or would like to be?
                </li>
                <li>Where are the holes in your business or operations?</li>
                <li>Why can’t you attract more well-paying clients?</li>
                <li>
                  What’s stopping you from making effective decisions to move
                  things forward?
                </li>
              </ul>
              <p>
                When you are so close to things, it’s hard to see what’s getting
                in the way of your own success.
              </p>
              <p>
                What could be a self-limiting weakness to you could actually be
                a strength in another business owner’s personality. In other
                words, what works for someone else may not work for you. Simply
                copying other business owners is not an effective strategy. We
                need to find the way forward that works for YOU.
              </p>
              <p>
                The only real way to find out what works is to have someone like
                me, a small business mentor, as your independent, experienced
                sounding board. Providing you with the non-judgemental support
                and assistance to address these unique challenges within your
                working life, ideally - from someone who has been through the
                trials and tribulations of entrepreneurship themselves.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white pb-5">
        <Container>
          <Row>
            <Col>
              {" "}
              <div className="iframe-container-new mb-4">
                <iframe
                  loading="lazy"
                  className="responsive-iframe"
                  src="https://www.youtube.com/embed/IFvfN7ScfwM"
                  title="Testimonial | Jason Cornes"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-primary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">Your hidden business mentoring partner</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Whether you’re an established business or a new start-up, let me
                tell you - the life of a self-employed small business owner is
                tough! On the bright side, it is exhilarating at times too!
              </p>
              <p>
                So firstly, congratulations, well done for getting this far. In
                my opinion, renouncing a regular salary in favour of a more
                unpredictable income stream takes courage and determination and
                self-discipline that few outside self-employment understands.
              </p>
              <p>
                It can be daunting at times, with so much to juggle and so much
                uncertainty - it’s easy to get distracted, to get stuck and to
                get caught like a rabbit in the headlights, unable to know in
                which direction to go.
              </p>
              <p>
                This is where my business mentoring comes into play; think of me
                as your hidden business partner, or counsellor...or agony Uncle
                —in your corner, listening, observing and offering unbiased,
                experienced hints, tips, suggestions and advice on the myriad of
                decisions you make on your business journey.
              </p>
            </Col>
          </Row>
          <Row className="text-center pt-4">
            <Col>
              <Button
                variant="outline-light"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Book a discovery meeting
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">What do business mentors do?</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Business mentoring isn’t about telling you what to do. After
                all, you went into business to be your own boss. It’s about
                working with you to find the way forward that works best for
                you. Sometimes this is about drawing the answers out which lie
                within you already and at other times it’s about offering a
                fresh perspective, idea or solution that you may not have
                thought of.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <h3 className="pb-3">Maximising your potential</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                All self-employed clients who come to me have different
                businesses, different personalities, circumstances and
                requirements. I offer a tailored relationship with you and your
                small business to exactly match your needs, encouraging you to
                develop the wisdom, skills and understanding that can best
                maximise your potential.
              </p>
              <p>
                With good business advice and your engagement, it’s likely
                you’ll see the results almost instantly, which will have an
                ongoing impact for months and years to come. In a nutshell,
                small, effective changes will be the compound effect you need
                along your pathway to greater success.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <h3 className="pb-3">Bespoke business mentoring sessions</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                These mentoring sessions will be bespoke to you depending on
                where you’re currently at and where you want to get to. The
                focus will sometimes be on the workings of your own mind, your
                personality, values, strengths and blind spots. At other times
                on your business plan and marketing activities, staff,
                colleagues and clients or customers.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="align-items-lg-center">
            <Col>
              {/* <StaticImage
                  className="float-lg-right service-page-body-image"
                  src="../../images/diagram.jpg"
                /> */}
              <h3 className="pb-3">Each mentoring session will involve:</h3>
              <ul className="pl-4">
                <li>Listening</li>
                <li>Getting strategic</li>
                <li>Changing your reality</li>
                <li>The money thing</li>
                <li>Agreed action points and accountability</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              {/* <StaticImage
                  className="float-lg-left my-3 service-page-body-image"
                  src="../../images/diagram.jpg"
                /> */}
              <h3 className="pb-3">
                A business strategy/MOT focus will create action plans covering:
              </h3>
              <ul className="pl-4">
                <li>Marketing</li>
                <li>Sales</li>
                <li>Financial</li>
                <li>Operational</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                I will help you develop a strategic business plan that will not
                only make you feel more confident in your abilities but will
                give you the edge you need to accomplish your goals faster and
                with less stress.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <h3 className="pb-3">
                Confidential mentoring for business owners{" "}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Every part of your business mentoring programme with me is
                totally confidential and completely tailored to your
                circumstances and your needs. With five years’ counselling and
                psychotherapy training, I fully recognise what it is to be
                human: we’re singular, different and unique.
              </p>
              <p>
                These business mentoring sessions are usually delivered
                fortnightly, either face to face or remotely via Zoom / Skype.
                This gives sufficient time for you to work on agreed projects or
                tasks between meetings with telephone and email support as may
                be required.
              </p>
              <p>
                During mentoring each session, to help you make the most of our
                time together, we’ll agree to a to-do list that you can work on
                for the following session. Holding you accountable in this way
                will get you closer step by step to achieving your goals.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
        <Container>
          <Row>
            <Col>
              <h3 className="pb-3">
                Nice words from my business mentoring clients{" "}
              </h3>
            </Col>
          </Row>
          <HorizontalTestimonial
            name="Aryajit Heppell. London Explainer Videos."
            review="I highly recommend Jason as an excellent Mentor and Business
              Coach. He’s very perceptive, methodical and experienced. He is
              also challenging when necessary. I leave our meetings feeling
              more motivated and clear on what I’m doing in my business. I’m
              thoroughly enjoying working with him."
            linkedIn="https://www.linkedin.com/in/aryajit-heppell-%F0%9F%9A%80-5a804538/"
            profilePic={aryajit}
          />
          <HorizontalTestimonial
            name="Business Owner – Liz George Wellbeing Centre"
            review="I started working with Jason to set up my therapy business. I had tried to do it on my own and after a few false starts, I realized I needed to find someone for business mentoring. I am very fortunate to have found Jason as he has helped me develop and implement a very successful business model and marketing strategy. This would never have been possible without him. Jason keeps me on track and is available to give feedback on any aspects of my business. He is a true professional and is always there to help and give support. I highly recommend Jason and consider myself very fortunate to have him as my business mentor."
            profilePic={liz}
            linkedIn="https://www.linkedin.com/in/liz-george-06471b166/"
          />
          <HorizontalTestimonial
            name="Dr Selda Koydemir"
            review="Jason's business mentoring, coaching and support helped me get clear on what I really want to do, where I want to go, what strengths I have and how I can use them to achieve my goals, and encouraged me to be more proactive around my business ideas. He has a friendly and challenging attitude which has put me at ease, but he also questioned my mindset and behaviours at the right time. I’m very glad that I’ve had the chance to work with such a great business mentor."
            profilePic={selda}
            linkedIn="https://www.linkedin.com/in/seldakoydemir/"
          />
        </Container>
      </section>
      <section className="bg-tertiary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">Ready for some business mentoring?</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Building, growing and sustaining a business is hard, but if you
                want to bring about change and are willing to put the work in,
                small business mentoring and coaching will be the game-changer
                you need.
              </p>
              <p>
                If this is you, with my knowledge and experience as a business
                mentor, alongside your determination to change, I can help you
                realise your true potential and get you and your business where
                you need it to be.
              </p>
              <p>
                Let’s chat over an initial coaching and mentoring discovery
                meeting. I’m looking forward to meeting you. Click below to
                book.
              </p>
            </Col>
          </Row>
          <Row className="text-center pt-4">
            <Col>
              <Button
                variant="outline-light"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Book a discovery meeting
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <CtaBanner
        headline="Business Mentoring"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
    </Layout>
  );
};
export default BusinessMentoringPage;
